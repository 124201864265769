import { Environment, EnvironmentType } from '@models/environment.model';
import { b2cConfigST } from './b2c-config';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://mainservice-tbums-st.azurewebsites.net/api/',
  apiVersionMain: 'v2',
  outcomeUrl: 'https://outcomeservice-tbums-st.azurewebsites.net/api/',
  apiVersionOutcome: 'v1',
  positionUrl: 'https://iotfunctions-tbums-st.azurewebsites.net/api',
  garageUrl: 'https://garageservice-tbums-st.azurewebsites.net/api/',
  apiVersionGarage: 'v1',
  enablePlanningPage: true,
  enableCalendarPage: true,
  enableRoutePage: true,
  enableSearchPage: true,
  enableOperatorUserManagement: true,
  enableNewOrderPage: true,
  appInsights: {
    instrumentationKey: 'bfcfb64c-e534-4acd-b54c-09ebffd4026c',
    isEnabled: true,
  },
  language: 'sv',
  enableBilling: true,
  enableUnboundOrder: true,
  environmentType: EnvironmentType.ST,

  b2c: b2cConfigST
};
