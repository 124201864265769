<div class="table-responsive">
  <table class="table custom-table datatable">
    <thead>
      <tr>
        <th i18n="@@order-list-table.order">Beställning</th>
        <th i18n="@@order-list-table.operator">Operatör</th>
        <th i18n="@@order-list-table.train">Tåg</th>
        <th i18n="@@order-list-table.startTripStop">Från</th>
        <th i18n="@@order-list-table.startTime">Avgångstid</th>
        <th i18n="@@order-list-table.endTripStop">Till</th>
        <th i18n="@@order-list-table.endTime">Ankomsttid</th>
        <th i18n="@@order-list-table.type">Typ</th>
        <th i18n="@@order-list-table.subContractor">Utförare</th>
        <th i18n="@@order-list-table.orderReason">Orsak</th>
        <th i18n="@@order-list-table.referenceId">Referens</th>
        <th i18n="@@order-list-table.comment">Kommentar</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of TableItems">
        <td>
          <div *ngIf="!order.isProductBased">
            <a [routerLink]="order.orderUrl">{{ order.orderId }}</a>
            <span class="badge">{{order.orderTypeShortString}}</span>
          </div>
          <div *ngIf="order.isProductBased">
            <a [href]="order.orderUrl">{{ order.orderId }}</a>
            <span class="badge">{{order.orderTypeShortString}}</span>
            <span class="draft" *ngIf="order.currentState === OrderState.Draft">Utkast</span>
          </div>
        </td>
        <td>{{order.operator}}</td>
        <td>{{order.trainNumber}}</td>
        <td>{{order.startTripStop}}</td>
        <td>{{order.startTime | amDateFormat:'YYYY-MM-DD HH:mm' }}</td>
        <td>{{order.endTripStop}}</td>
        <td>{{order.endTime===null ?'': order.endTime | amDateFormat:'YYYY-MM-DD HH:mm' }}</td>
        <td>{{order.vehicleType}}</td>
        <td>{{order.subContractor}}</td>
        <td>{{order.orderReason}}</td>
        <td>{{order.referenceId}}</td>
        <td><div [innerHtml]="order.comment"></div></td>
      </tr>
    </tbody>
  </table>
</div>
