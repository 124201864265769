export class Operator {
    id: number;
    name: string;
    operatorOrganisationId: number;
}

//CtlNorway
export const GO_AHEAD = 1317;
export const SJ_NORD = 1394;
export const CtlNorgeOperatorIds = [GO_AHEAD, SJ_NORD];

//CtlSyd
export const ORESUNDSTAG_TRANSDEV = 1726;
export const SKANETRAFIKEN = 1450;
export const CtlSydOperatorIds = [ORESUNDSTAG_TRANSDEV, SKANETRAFIKEN];

//CtlMitt
export const VR_SNABBTAG = 1288;
export const SL_PENDELTAG_SJ = 1806;
export const SL_TUNNELBANAN_MTR = 1124;
export const STEGRA = 1834;
export const CtlMittOperatorIds = [VR_SNABBTAG, SL_PENDELTAG_SJ, SL_TUNNELBANAN_MTR, STEGRA];

//CtlVäst
export const VY_NORRTAG = 1120;
export const VY_VARMLANDSTRAFIK = 1284;
export const VASTTRAFIK = 1848;
export const VY_NORGE = 1283;
export const VY_X_TAGET= 1285;
export const CtlVastOperatorIds = [VY_NORRTAG, VY_VARMLANDSTRAFIK, VASTTRAFIK, VY_NORGE, VY_X_TAGET];

// NoCtl
export const METRO_KOBENHAVN = 1814;
export const VR_FINLAND = 1813;
export const EVENT = 1756;
export const TEST_TRAFIK_OPERATOR = 1684;
export const CtlNoneOperatorIds = [METRO_KOBENHAVN, VR_FINLAND, EVENT, TEST_TRAFIK_OPERATOR];