export interface Environment {
  production: boolean;
  apiUrl: string;
  apiVersionMain: string;
  outcomeUrl: string;
  apiVersionOutcome: string;
  positionUrl: string;
  garageUrl: string;
  apiVersionGarage: string;
  enablePlanningPage: boolean;
  enableCalendarPage: boolean;
  enableRoutePage: boolean;
  enableSearchPage: boolean;
  enableOperatorUserManagement: boolean;
  enableNewOrderPage: boolean;
  appInsights: {
    instrumentationKey: string,
    isEnabled: boolean;
  };
  language: string;
  enableBilling: boolean;
  enableUnboundOrder: boolean;
  environmentType: EnvironmentType;

  b2c: B2cConfig;

}

export enum EnvironmentType {
  Production = 'Production',
  ST = 'ST',
  AT = 'AT',
  FT = 'FT',
  Local = 'Local',
}

export interface B2cConfig {
  mainServiceScopes: string[];
  garageServiceScopes: string[];
  outcomeServiceScopes: string[];
  iotFunctionsServiceScopes: string[];
  authorityDomain: string;
  authority: string;
  clientId: string;
  tenantId: string;
  redirectUrl: string;
}
