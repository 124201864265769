import { Component, Input } from '@angular/core';
import { OrderTripsPageResult } from '../../common/models/order';
import { UserRoles } from '../../authentication/user-roles';
import { AuthService } from '../../common/services/auth.service';
import { OrderState } from '@models/order-state';

@Component({
  selector: 'app-order-list-table',
  templateUrl: './order-list-table.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListTableComponent {
  @Input() TableItems: OrderTripsPageResult[];

  constructor(private authService: AuthService) {}

  UserRoles = UserRoles;
  OrderState = OrderState;
  
  userHasAnyRole(userRoles: UserRoles[]) {
    return this.authService.userHasAnyRole(userRoles);
  }
}
