<div class="container">
    <h1 class="mt-5" i18n="@@order-confirmation.order-performed">Beställning utförd</h1>

    <p i18n="@@order-confirmation.information">Vi har mottagit din beställning och kommer återkoppla snarast möjligt.</p>

    <div class="bs-callout-info mb-3" routerLink="/order/{{ orderId }}" (keyup.enter)="navigateToOrder()">
      <div id="openOrderToActivate" class="title" i18n="@@order-confirmation.order-id">Beställningsnummer</div>
      <div>{{ orderId }}</div>
      <div *ngIf="referenceId" class="title" i18n="@@order-confirmation.reference">Referens</div>
      <div>{{ referenceId }}</div>
    </div>

    <hr/>

    <p i18n="@@order-confirmation.questions">Vid frågor kontakta</p>

    <div *ngIf="isCtlMitt">
      <div class="mt-3"><a href="mailto:ctl.mitt.tagersattning@nobina.se" target="_top">ctl.mitt.tagersattning@nobina.se</a></div>
      <div class="mt-2 mb-5"><a href="tel:08 4000 21 35" target="_top">08-4000 21 35</a></div>
    </div>

    <div *ngIf="isCtlVast">
      <div class="mt-3"><a href="mailto:dl-ctlvast@nobina.se" target="_top">dl-ctlvast@nobina.se</a></div>
      <div class="mt-2 mb-5"><a href="tel:08 4000 21 39" target="_top">08-4000 21 39</a></div>
    </div>

    <div *ngIf="isCtlSyd">
      <div class="mt-3"><a href="mailto:dl-ctlsyd@nobina.se" target="_top">dl-ctlsyd@nobina.se</a></div>
      <div class="mt-2 mb-5"><a href="tel:0418 666 317" target="_top">0418-666 317</a></div>
    </div>

    <div *ngIf="isCtlNorge">
      <div class="mt-3"><a href="mailto:erstatningstrafikk@nobina.no" target="_top">erstatningstrafikk@nobina.no</a></div>
      <div class="mt-2 mb-5"><a href="tel:413 43 994" target="_top">413 43 994</a></div>
    </div>

    <div *ngIf="isNoCtl">
      <div class="mt-3" i18n="@@order-confirmation.no-contact-details">Ingen kontaktinfo finns än.</div>
    </div>

</div>
