import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from '../../../common/services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '../../../common/models/order';
import * as Operator from '../../../common/models/operator';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit {
  @Input() order: Order;
  orderId = '';
  referenceId = '';
  KEY_ENTER = 13;
  KEY_SPACE = 32;

  isCtlMitt = false;
  isCtlSyd = false;
  isCtlVast = false;
  isCtlNorge = false;
  isNoCtl = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.orderService.getOrder(id)
      .subscribe(order => this.onOrderRetrieved(order.body));
  }

  onOrderRetrieved(order: Order): void {
    this.order = order;
    this.orderId = order.id + '';
    this.referenceId = order.referenceId;

    this.isCtlNorge = Operator.CtlNorgeOperatorIds.includes(this.order.operator.operatorOrganisationId);

    this.isCtlMitt = Operator.CtlMittOperatorIds.includes(this.order.operator.operatorOrganisationId);

    this.isCtlSyd = Operator.CtlSydOperatorIds.includes(this.order.operator.operatorOrganisationId);

    this.isCtlVast = Operator.CtlVastOperatorIds.includes(this.order.operator.operatorOrganisationId);

    this.isNoCtl = Operator.CtlNoneOperatorIds.includes(this.order.operator.operatorOrganisationId)
                    || !(this.isCtlNorge || this.isCtlMitt || this.isCtlSyd || this.isCtlVast);
  }

  navigateToOrder() {
      this.router.navigateByUrl('/order/' + this.orderId);
  }
}
