import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Operator from '../../../common/models/operator';
import { UnboundOrder } from '../../../common/models/unbound-order';
import { UnboundOrderService } from '../../../common/services/unbound-order.service';

@Component({
  selector: 'app-unbound-order-confirmation',
  templateUrl: './unbound-order-confirmation.component.html',
  styleUrls: ['./unbound-order-confirmation.component.scss']
})
export class UnboundOrderConfirmationComponent implements OnInit {
  @Input() order: UnboundOrder;
  orderId = '';
  referenceId = '';
  KEY_ENTER = 13;
  KEY_SPACE = 32;

  isCtlMitt = false;
  isCtlSyd = false;
  isCtlVast = false;
  isCtlNorge = false;
  isNoCtl = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private unboundOrderService: UnboundOrderService) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.unboundOrderService.getOrder(id)
      .subscribe(order => this.onOrderRetrieved(order.body));
  }

  onOrderRetrieved(order: UnboundOrder): void {
    this.order = order;
    this.orderId = order.id + '';
    this.referenceId = order.referenceId;

    this.isCtlNorge = Operator.CtlNorgeOperatorIds.includes(this.order.operator.operatorOrganisationId);

    this.isCtlMitt = Operator.CtlMittOperatorIds.includes(this.order.operator.operatorOrganisationId);

    this.isCtlSyd = Operator.CtlSydOperatorIds.includes(this.order.operator.operatorOrganisationId);

    this.isCtlVast = Operator.CtlVastOperatorIds.includes(this.order.operator.operatorOrganisationId);

    this.isNoCtl = Operator.CtlNoneOperatorIds.includes(this.order.operator.operatorOrganisationId)
                    || !(this.isCtlNorge || this.isCtlMitt || this.isCtlSyd || this.isCtlVast);
  }

  navigateToOrder() {
    this.router.navigateByUrl('/unboundorder/' + this.orderId);
  }
}
